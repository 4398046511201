"use client";

import { createLevoClient, type WorkspaceID } from "@levo-so/core";

export const levoClient = createLevoClient({
	apiUrl: "/.levo/public/api",
	insightsUrl: "/.levo/insights/api",
	workspace: process.env.NEXT_PUBLIC_LEVO_WORKSPACE as WorkspaceID,
	NODE_ENV: process.env.NODE_ENV,
	APP_MODE: "production",
});
